import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import aboutImg from "../images/intro.jpg";
import pdf1 from "../pdf/samtidensintressefordatidenslandskap.pdf";
import pdf2 from "../pdf/stenkustensKulturlandskap.pdf";

class om extends React.Component {
  render() {
    const siteTitle = "Fornbod - Om";

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle} />

        <div className="wrapper">
          <h2>Om oss</h2>
          <p>
            Fornbods samfällighetsförening är en samfällighet på norra Öland
            vars medlemmar ansvarar för underhållet av den enskilda vägen Horns
            Kustväg. En samfällighet är ”en sammanslutning av markägare för
            förvaltning av gemensam egendom”, som ansvarar för att sköta
            underhållet av en enskild väg som ansluter till två eller flera
            fastigeter. Underhållet kan exempelvis innebära vinterväghållning
            eller hyvling av grusväg. En enskild väg förhåller sig annorlunda
            från en allmän väg bland annat eftersom staten eller kommunen inte
            har något ansvar för dess underhåll eller standard. Underhållet av
            enskilda vägar utförs via eget arbete eller med hjälp av upphandlade
            tjänster.
          </p>
          <img src={aboutImg} alt="Bild på vägen"></img>

          <h3>Avhandlingar</h3>
          <div className="pdfLinks">
            <a href={pdf1} target="_blank" rel="noopener noreferrer">Samtidens intresse för dåtidens landskap</a>
            <a href={pdf2} target="_blank" rel="noopener noreferrer">Stenkustens kulturlandskap</a>
          </div>
        </div>
      </Layout>
    );
  }
}

export default om;
